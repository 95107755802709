import { deferLoading, loadingDone } from '@mfl/framework';
import { AccountBasicInfoResponse, userGateway } from '@msl/user-gateway-sdk';

import { auth4Sdks } from './auth';
import { doHeaderSetup } from './header';

export async function platformBootstrap() {
  console.info('platformBootstrap start');

  let accountData: AccountBasicInfoResponse | undefined;
  try {
    deferLoading();
    await userGateway.init(auth4Sdks());
    accountData = await userGateway.accountBasicInfo({});

    doHeaderSetup(accountData);
  } catch (e) {
    console.error('get account basic info failed', e);
    throw e;
  } finally {
    loadingDone();
  }
}
